import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState, memo } from "react";
import {
  Nav,
  Navbar as BSNavbar,
  NavbarToggler,
  Collapse,
  NavItem,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import useApi from "~/hooks/use-api";

interface User {
  name: string;
  email: string;
}

const LogoutForm = memo(function LogoutForm() {
  const { data: user } = useApi<User>("/api/me");

  return (
    <form className="form-inline" method="POST" action="/auth/logout">
      <div className="form-group">
        <label className="control-label text-light ml-md-3 mr-3">
          {user ? user.name : ""}{" "}
        </label>
        <button type="submit" className="btn btn-outline-light">
          Log Out
        </button>
      </div>
    </form>
  );
});

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname = "" } = useRouter();
  const toggle = () => setIsOpen(!isOpen);

  return (
    <BSNavbar color="dark" dark expand="md">
      <Container>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavLink title="Dashboard" href="/" active={pathname === "/"} />

            <NavLink
              title="Orgs"
              href="/orgs"
              active={pathname.startsWith("/orgs")}
            />

            <NavLink
              title="Analytics"
              href="/tools/quicksight/d0b3fb05-75c1-449b-b13b-70bdbf499315"
              active={pathname.startsWith(
                "/tools/quicksight/d0b3fb05-75c1-449b-b13b-70bdbf499315"
              )}
            />

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Tools
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownLink title="Releases" href="/tools/releases" />
                <DropdownLink title="Roadmap" href="/tools/roadmap" />
                <DropdownLink title="Github Issues" href="/tools/issues" />
                <DropdownLink
                  title="Stripe Accounts"
                  href="/tools/stripe/accounts"
                />
                <DropdownLink title="RPC Services" href="/tools/rpc-services" />
                <DropdownLink title="Global Auth" href="/global-auth/users" />
                <DropdownLink title="Warnings" href="/tools/warnings" />
                <DropdownLink
                  title="Feature Flags"
                  href="https://unleash.loke.global"
                />
                <DropdownLink
                  title="Phone numbers"
                  href="/tools/phone-numbers"
                />
                <DropdownLink
                  title="Login Rate Limiter"
                  href="/tools/login-rate-limiter"
                />
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <LogoutForm />
        </Collapse>
      </Container>
    </BSNavbar>
  );
}

function NavLink(props: { title: string; href: string; active?: boolean }) {
  return (
    <NavItem active={props.active}>
      <Link href={props.href}>
        <a className="nav-link">{props.title}</a>
      </Link>
    </NavItem>
  );
}

function DropdownLink(props: { title: string; href: string }) {
  return (
    <Link href={props.href} passHref>
      <DropdownItem tag="a">{props.title}</DropdownItem>
    </Link>
  );
}
