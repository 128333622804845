import Head from "next/head";
import Link from "next/link";
import React, { ReactNode, memo } from "react";
import Navbar from "./Navbar";
import { NextComponentType, NextPageContext } from "next";

export type LayoutComponent = NextComponentType<NextPageContext, any> & {
  layoutProps?: LayoutProps;
};

interface LayoutProps {
  fullPage?: boolean;
}

interface Props extends LayoutProps {
  children: ReactNode;
}

export default memo(function Layout(props: Props) {
  if (props.fullPage) {
    return <>{props.children}</>;
  }
  return (
    <div className="d-flex flex-column min-vh-100">
      <Head>
        <title>Operator</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="icon" href="/favicon.png" />
      </Head>

      <Navbar />

      <div className="flex-shrink-0 flex-grow-1">{props.children}</div>

      <style jsx>{`
        .footer a {
          color: #666;
        }
        .footer a:hover {
          color: #999;
          text-decoration: none;
        }
      `}</style>
      <footer className="container footer">
        <p>
          <Link href="/contributors" passHref>
            <a>❤️ LOKE Engineering</a>
          </Link>
        </p>
      </footer>
    </div>
  );
});
