import App from "next/app";
import React from "react";
import Layout, { LayoutComponent } from "~/components/Layout";
import GoogleAnalytics from "~/components/GoogleAnalytics";

import "moment-timezone";

import "bootstrap/dist/css/bootstrap.css";

interface Props {
  err?: Error;
}

class OperatorApp extends App<Props> {
  // Only uncomment this method if you have blocking data requirements for
  // every single page in your application. This disables the ability to
  // perform automatic static optimization, causing every page in your app to
  // be server-side rendered.
  //
  // static async getInitialProps(appContext) {
  //   // calls page's `getInitialProps` and fills `appProps.pageProps`
  //   const appProps = await App.getInitialProps(appContext);
  //
  //   return { ...appProps }
  // }

  render() {
    const { Component, pageProps, err } = this.props;

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const modifiedPageProps = { ...pageProps, err };

    return (
      <Layout {...((Component as LayoutComponent).layoutProps || {})}>
        <GoogleAnalytics />
        <Component {...modifiedPageProps} />
      </Layout>
    );
  }
}

export default OperatorApp;
